import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

type Props = {
  className?: string
}

function AboutImage({ className }: Props) {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "about-me-cody-calligraphy.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 400
            quality: 50
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
  `)
  return (
    <GatsbyImage
      image={getImage(data.file)}
      className={className}
      alt="Toronto special event calligrapher poses for a head shot with calligraphy tools."
    />
  )
}

export default AboutImage
