import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import AboutImage from '../components/AboutImage'
import Box from '../components/Box'
import HoneybookInquiryForm from '../components/HoneybookInquiryForm'
import Layout from '../components/Layout'
import PagePadding from '../components/PagePadding'
import Seo from '../components/Seo'

function ContactPage({
  data,
  // TODO: Remove old contact form prefill mechanism (via location)
  // location,
}) {
  return (
    <Layout
      mainBackground="#ffffff"
      pageData={data?.sanityPage}
      pageTitle="Contact"
    >
      <Seo
        title="Contact"
        description="Get in touch with Cody about workshops, on-site events, wedding
              calligraphy, or general inquiries."
      />
      <Content>
        <ContactFormWithLayout />
        <Aside>
          <AboutImageWithLayout />
          <Box>
            Get in touch with me about workshops, on-site events, wedding
            calligraphy, or anything that&apos;s on your mind!
          </Box>
        </Aside>
      </Content>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    sanityPage(uuid: { eq: "contact" }) {
      _rawPageMessage
    }
  }
`

const Content = styled(PagePadding)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (min-width: 750px) {
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const ContactFormWithLayout = styled(HoneybookInquiryForm)`
  width: 100%;
  margin: 0;
  @media (min-width: 750px) {
    flex: 1;
    margin-right: 2rem;
  }
`

const Aside = styled.aside`
  margin: 2rem 0 0;
  max-width: 100%;
  width: 400px;
  @media (min-width: 750px) {
    margin-top: 1.125rem;
  }
`

const AboutImageWithLayout = styled(AboutImage)`
  margin-bottom: 1rem;
`

export default ContactPage
